
body {
  margin: 0;
  padding: 0;
  font-family: "Apercu",Helvetica,Arial,sans-serif;
  font-size: 21px;
  line-height: 1.42857143;
  color: #000;
}

code {
  font-family: "Apercu",Helvetica,Arial,sans-serif;
}

html, body { 
  height: 100%; 
  min-height: 100%;
}

#root{
  height: inherit;
}