#homeBanner{
    height: 500px;
    width: 100%;
    background-image: url("/images/austin.jpg");
    background-size: cover;
    background-position: center;
}
#homeSection{
    text-align: center;
    font-family: "eurostile",sans-serif;
    padding-top: 55px;
    background-color: #fafafa;
}
#homeHeader{
    color: #293347;
    font-weight: 700;
    letter-spacing: -0.5px;
    font-family: "eurostile",sans-serif;
}
#homeProjectsHeader{
    text-align: center;
    margin-top: 35px;
}
#homeProjectsLink{
    padding-top:25px;
    padding-bottom: 45px;
}
#react{
    color: #61dafb;
}
.homeP{
    margin-bottom: 25px;
    color: rgb(133, 132, 132);
}

@media (max-width: 784px) { 
    #homeSection{
        text-align: center;
    }
    #homeProjects{
       padding-left: 15px;
       padding-right: 15px;
    }
    #homeProjectsBtn{
        text-align: center;
    }
    #homeBanner{
        height: 300px;
        width: inherit;
    }
}

@media (max-width: 320px) { 
    #homeBanner{
        height: 200px;
        width: inherit;
    }
}

@media (min-width: 800px) { 
    #homeSection{
        height:300px;
        border-bottom: 1px solid rgb(202, 201, 201);
    }
}