#projectsHeader{
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #141414;
    font-family: "Apercu",Helvetica,Arial,sans-serif;
    margin-left: 5%;
    margin-top: 25px;
}
#projectsHeader::before{
    background-color: rgb(182, 182, 182);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 48px;
    right: 16px;
    margin-left: 16px;
}

@media (max-width: 767.98px) { 
    .projectsPage{
        padding-left: 15px;
        padding-right: 15px;
    }
}
