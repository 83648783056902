.navbar{
    height: 80px;
    padding-left: 25px;
    padding-right: 25px;
    /* border-bottom: 2px #fff2f2 solid; */
    /* background: #f7f7f7; */
  }
  .custom-toggler.navbar-toggler{
    padding: .25rem .35rem;
    font-size: 1.25rem;
    line-height: 1;
    border: 2px solid black;
    border-radius: .15rem;
    color: black;
    border-color:black;
  }
  .custom-toggler .navbar-toggler-icon{
    color: black;
    border-color:black;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .nav-link{
    color: black;
  }
  .nav-link:hover{
    color: rgba(20,20,20,.24);
  }
  .navbar-nav{
  margin: 0 0 0 20px;
  padding: 12px 10px;
  }
  .navbar-logo{ 
    height: 55px;
  }
  .social{
    margin-top:20px;
  }
  @media (max-width: 992px) {  
    .nav-link{
      padding: 5px;
      text-align: center;
      border-top-width: 1px;
      border-top-style: solid;
      border-color: black;
    }
  }
  img {
    max-width: 100%;
  }

  .navbar-nav.mr-auto{
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    margin-top:7px;
  }
  .collapse.navbar-collapse.show{
    padding-left: 25px;
    padding-right: 25px;
    z-index: 900;
    background: #ffffff;
    opacity: 0.7;
  }

  .navbar-brand {
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    overflow: visible;
    border: 3px solid #494c4e;
    position: relative;
    transform: rotate(0deg);
    transition: transform .4s ease-in-out;
}

.navbar-brand:hover {
  opacity: 1;
  transform: rotate(-90deg);
  transition: transform .4s ease-in-out;
}

.logo-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: transform .4s ease-in-out;
  font-weight: bold;
  font-size: 13px;
  color: #494c4e;
}
