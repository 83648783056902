.socialImage{
    width: 35px;
    height: 35px;
}

.social-item{
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 375px) { 
    .social-item{
        padding-top: 15px;
    }
    .socialImage{
        width: 25px;
        height: 25px;
    }
}

.socialImage:hover{
    transform: translateY(-5px);
  }