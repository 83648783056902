.App {
  text-align: center;
}

#secondRoot{
  height: inherit;
}

#main{
  min-height: 70%;
}