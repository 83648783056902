.footer{
    height: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 100px 0;
    border-top: 1px #dedede solid;
    background: #f7f7f7;

  }
  #footerText{
    text-align: center; 
  }
