.card {
  max-width: 318.25px;
  background: #fff;
  overflow: hidden;
  margin: 1rem;
  padding: 0;
  border: 1px solid rgb(221, 220, 220);
  -moz-box-shadow: 0 0 10px #ccc; 
  -webkit-box-shadow: 0 0 10px #ccc; 
  box-shadow: 0 0 10px #ccc;
}
.card-body{
  height: 40%;
}
.projectCardImg{ 
  max-height: 240px;
  width: 100%;
  border-bottom: 1px solid rgb(221, 220, 220);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
   -ms-transition: all 1s ease;
       transition: all 1s ease;
} 
.projectCardImg:hover {
  -webkit-filter: blur(2px); /* Safari */
  filter: blur(2px);
}
.projectLink{
  color: #212529;
}
.projectLink:hover {
  color: rgba(10, 10, 10, 0.295);
  text-decoration: none;
}
.card-title{
  color: #141414;
  text-transform: uppercase;
  font-family: "Apercu",Helvetica,Arial,sans-serif;
}
.card-text{
  color: #141414;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 14px;
}
