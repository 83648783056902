.contactSection{
    margin-top: 5%;
    margin-bottom: 5%;
}

#formSection{
    padding-left: 10%;
}

@media (min-width: 320px) { 
    #formSection{
        padding-left: 15px;
    }
}