.contactForm .req{
    color: #f74545;
    margin-left: 3px;
}

.contactForm label{
    display: block;
}

textarea{
    width: 20em;
}
.contactForm .formInput{
    border: 1px solid rgba(0,0,0,0.15);
    margin-bottom: 16px;
}

.errorMsg{
    color: #f74545;
}

#submitBtn{
    display: block;
    margin-top: 1em;
    background-color: black;
    color: #fff;
    border: none;
    padding: 0.5em 1.35em;
    margin-bottom: 2em;
    border: 2px solid black;
}

#submitBtn:hover{
    background-color: #fff;
    color: black;
    border: 2px solid black;
}

@media (max-width: 320px) { 
    textarea{
        height: 4em;
        width: 15em;
    }
}

@media (min-width: 640px) { 
    textarea{
        height: 4em;
        width: 30em;
    }
}